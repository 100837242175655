<template>
  <div class="container-pc">
    <div class="tab-view Global-W">
      <div class="tab-left">
        <div :class="['tab-left-item']" v-for="(item, index) in tabList" :key="index">
          <a :class="[route.name == item.name ? 'select-tab' : '', 'HOVERVIEW HOVER']" @click="handleChange(item)">
            {{ $t(item.i18n) }}</a>
        </div>
      </div>
      <div class="tab-right">
        <div class="tab-title" v-if="route.name != 'Brand' || route.name != 'Channel'">{{ $t(selectTab.i18n) }}</div>
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div class="container-m p-30">
    <div class="tab-right">
      <div class="tab-title-m">{{ $t(selectTab.i18n) }}</div>
      <router-view></router-view>
    </div>
  </div>
</template>



<script setup name="Footer">
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const tabList = ref([
  {
    title: "购买流程",
    path: "/footer/process",
    English: 'Purchase Process',
    name: 'Process',
    i18n: "Footer.Purchase"
  },
  {
    title: "支付方式",
    name: 'Payment',
    path: '/footer/payment',
    English: "Payment Method",
    i18n: "Footer.Payment"
  },
  {
    title: "常见问题",
    name: 'Question',
    path: "/footer/question",
    English: 'Frequently Asked Questions',
    i18n: "Footer.Question"

  },
  {
    title: "退货",
    path: "/footer/refund",
    name: 'Refund',
    English: "Refund Explain",
    i18n: "Footer.Refund"
  },
  {
    title: "配送方式",
    path: "/footer/delivery",
    name: 'Delivery',
    English: 'Delivery Method',
    i18n: "Footer.Delivery"
  },
  {
    title: "配送服务",
    path: "/footer/service",
    name: 'Service',
    English: 'Delivery Service',
    i18n: "Footer.Service"

  },
  {
    title: "品牌介绍",
    path: "/footer/brand",
    name: 'Brand',
    English: 'Brand introduction',
    i18n: "Footer.Brand"

  },
  {
    title: "销售渠道",
    path: "/footer/channel",
    name: 'Channel',
    English: 'Marketing Channel',
    i18n: "Footer.Channel"
  },
  {
    title: "联系我们",
    name: 'About',
    path: '/footer/about',
    English: "Contact us",
    i18n: "Footer.Aboutus"
  },
  {
    title: "加入我们",
    name: 'Joinus',
    path: '/footer/join',
    English: "Join Us",
    i18n: "Footer.Joinus"

  },

  // {
  //   title: "保养与维修",
  //   name: 'Maintenance',
  //   path: "/footer/maintenance",
  //   English: "Maintenance Explain",
  //   i18n: "Footer.Maintenance"

  // },
  // {
  //   title: "指圈测量",
  //   name: 'Finger',
  //   path: "/footer/finger",
  //   English: "Finger Circle Measurement",
  //   i18n: "Footer.Finger"

  // },
]);
const route = useRoute()
const router = useRouter()
console.log('route', route.name)
const selectTab = ref({})


selectTab.value = tabList.value[0]


const handleChange = (item) => {
  selectTab.value = item
  router.push({
    path: item.path
  })
}

onMounted(() => {
  selectTab.value = tabList.value[0]
  tabList.value.forEach((item, index) => {
    if (item.name == route.name) {
      selectTab.value = tabList.value[index]
    }
  })
}) 
</script>



<style lang="scss" scoped>
.tab-view {
  display: flex;
  width: 100%;
  margin-top: 20px;

  .tab-left {
    width: 200px;

    // background-color: #d9d9d9;
    .tab-left-item {
      padding: 20px 20px;
      box-sizing: border-box;
    }

    .select-tab:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #d31334;
    }
  }

  .tab-right {
    flex: 1;
    padding-left: 20px;

    .tab-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      font-size: 25px;
    }


  }

}

.p-30 {
  padding: 0 .5rem;
}

.tab-title-m {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: 700;
  font-size: .35rem;
}
</style>